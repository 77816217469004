const planComparisons = [
  {
    feature: "Feature",
    info: "",
    individual: true,
    team: true,
    enterprise: true,
  },
  {
    feature: "Feature",
    info: "Info",
    individual: true,
    team: true,
    enterprise: true,
  },
  {
    feature: "Feature",
    info: "Info",
    individual: true,
    team: true,
    enterprise: true,
  },
  {
    feature: "Feature",
    info: "",
    individual: true,
    team: true,
    enterprise: true,
  },
  {
    feature: "Feature",
    info: "Info",
    individual: true,
    team: true,
    enterprise: true,
  },
  {
    feature: "Feature",
    info: "",
    individual: true,
    team: true,
    enterprise: true,
  },
  {
    feature: "Feature",
    info: "Info",
    individual: true,
    team: true,
    enterprise: true,
  },
  {
    feature: "Feature",
    info: "Info",
    individual: false,
    team: true,
    enterprise: true,
  },
  {
    feature: "Feature",
    info: "Info",
    individual: false,
    team: true,
    enterprise: true,
  },
  {
    feature: "Feature",
    info: "Info",
    individual: false,
    team: false,
    enterprise: true,
  },
  {
    feature: "Feature",
    info: "Info",
    individual: false,
    team: false,
    enterprise: true,
  },
  {
    feature: "Feature",
    info: "Info",
    individual: true,
    team: true,
    enterprise: true,
  },
  {
    feature: "Feature",
    info: "Info",
    individual: true,
    team: true,
    enterprise: true,
  },
  {
    feature: "Feature",
    info: "Info",
    individual: true,
    team: true,
    enterprise: true,
  },
  {
    feature: "Feature",
    info: "Info",
    individual: true,
    team: true,
    enterprise: true,
  },
]

export default planComparisons
