import React from "react"
import styled from "styled-components"
import * as Buttons from "../components/Buttons"
import * as Containers from "../components/Containers"
import Faqs from "../components/Data/faqs"
import PlanComparisons from "../components/Data/planComparisons"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as Tables from "../components/Tables"
import PricingTable from "../components/Tables/PricingTable"
import HoverInfo from "../images/Info-2_v1.inline.svg"

const IconContainer = styled.div`
  position: relative;

  :hover #ToolTip {
    display: block;
  }
`

const ToolTip = styled.div`
  display: none;
  position: absolute;
  left: 50%;
  bottom: 100%;
  z-index: 100;

  padding: 10px 20px;

  border: 1px solid #b3c9ce;
  border-radius: 4px;
  text-align: center;
  font: italic 14px/1.3 sans-serif;
  color: #333;
  background: #fff;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
`

const HeroContent = styled.div`
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  width: 65%;
  margin-bottom: 24px;

  @media screen and (max-width: 1024px) {
    margin-top: 0px;
    margin-bottom: 24px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    text-align: left;
  }
`

const PricingPage = () => {
  const [yearly, setYearly] = React.useState(false)
  const [isExpanded, setIsExpanded] = React.useState(false)

  const toggleYearly = () => {
    if (!yearly) {
      setYearly(true)
    }
    setYearly(!yearly)
  }

  const toggleExpand = () => {
    if (!isExpanded) {
      setIsExpanded(true)
    }
    setIsExpanded(!isExpanded)
  }

  return (
    <Layout>
      <SEO title="Pricing" />

      <Containers.PageContainer>
        <Containers.SectionContainer
          style={{
            flexDirection: "column",
            maxWidth: "100%",
            justifySelf: "center",
          }}
        >
          {/* <div
            style={{
              alignSelf: "center",
              marginTop: "auto",
              marginBottom: "auto",
              textAlign: "center",
              width: "75%",
            }}
          >
            <h3 style={{ marginTop: "3rem", marginBottom: "3rem" }}>
              Start managing all your relationships, closing transactions faster
              and measuring your business success with performance indicators.
            </h3>
          </div> */}
          <HeroContent>
            <h3>
              Build and nurture relationships, close transactions faster and
              measure successes that comes along.
            </h3>
          </HeroContent>
          <div class="cd-pricing-switcher">
            <p class="fieldset">
              <input
                type="radio"
                name="duration"
                value="monthly"
                id="monthly"
                onClick={() => toggleYearly()}
                // onClick={e => alert(e.target.value)}
                checked={!yearly}
              />
              <label for="monthly">Pay Monthly</label>
              <input
                type="radio"
                name="duration"
                value="yearly"
                id="yearly"
                onClick={() => toggleYearly()}
                checked={yearly}
                // onClick={e => alert(e.target.value)}
              />
              <label for="yearly">Pay Yearly</label>
              <span class="cd-switch"></span>
            </p>
          </div>
          <PricingTable yearly={yearly} />
          <div
            style={{
              alignSelf: "center",
              marginTop: "auto",
              marginBottom: "auto",
              textAlign: "center",
              width: "75%",
            }}
          >
            <h6
              style={{
                margin: "16px auto 0",
                textAlign: "center",
                color: "#718096",
                fontSize: "0.875rem",
                fontWeight: 400,
              }}
            >
              *You will not be charged until your free trial expires. We will
              notify you 7 days before trial expiry. No commitments, you can
              cancel anytime.
            </h6>
          </div>
        </Containers.SectionContainer>
      </Containers.PageContainer>
    </Layout>
  )
}

export default PricingPage

{
  /* <Containers.SectionContainer style={{ flexDirection: "column" }}>
          <div
            style={{
              alignSelf: "center",
              marginTop: "auto",
              marginBottom: "auto",
              textAlign: "center",
              width: "80%",
            }}
          >
            <h4>
              <span>We’re committed to simple, transparent pricing.</span>
            </h4>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
            </p>
          </div>
        </Containers.SectionContainer>
        <Containers.SectionContainer style={{ flexDirection: "column" }}>
          <Containers.SectionHeaderArea>
            <h2>Compare Uuoni plans</h2>
          </Containers.SectionHeaderArea>

          <Tables.TableDesktop>
            <thead>
              <tr style={{ borderBottom: "2px solid #6386b3" }}>
                <Tables.TableHeaderColumnTitle />
                <Tables.TableHeaderColumnValue>
                  <h4>Individual</h4>
                </Tables.TableHeaderColumnValue>
                <Tables.TableHeaderColumnValue>
                  <h4>Team</h4>
                </Tables.TableHeaderColumnValue>
                <Tables.TableHeaderColumnValue>
                  <h4>Enterprise</h4>
                </Tables.TableHeaderColumnValue>
              </tr>
            </thead>
            <tbody>
              {isExpanded
                ? PlanComparisons.map((item, index) => {
                    return (
                      <Tables.TableRow>
                        <Tables.TableRowColumnName>
                          <p
                            style={{
                              fontWeight: "bold",
                              marginRight: "1.5rem",
                            }}
                          >
                            {item.feature}
                          </p>
                          {item.info && (
                            <IconContainer>
                              <HoverInfo
                                style={{
                                  width: "2rem",
                                  minWidth: "2rem",
                                  height: "2rem",
                                  minHeight: "2rem",
                                }}
                              />
                              <ToolTip id="ToolTip">{item.info}</ToolTip>
                            </IconContainer>
                          )}
                        </Tables.TableRowColumnName>
                        <Tables.TableRowColumnChecked>
                          {item.individual && (
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 24 24"
                              aria-label="Included"
                              class="css-fpz9h8"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                            </svg>
                          )}
                        </Tables.TableRowColumnChecked>
                        <Tables.TableRowColumnChecked>
                          {item.team && (
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 24 24"
                              aria-label="Included"
                              class="css-fpz9h8"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                            </svg>
                          )}
                        </Tables.TableRowColumnChecked>
                        <Tables.TableRowColumnChecked>
                          {item.enterprise && (
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 24 24"
                              aria-label="Included"
                              class="css-fpz9h8"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                            </svg>
                          )}
                        </Tables.TableRowColumnChecked>
                      </Tables.TableRow>
                    )
                  })
                : PlanComparisons.map((item, index) => {
                    if (index <= 10)
                      return (
                        <Tables.TableRow>
                          <Tables.TableRowColumnName>
                            <p
                              style={{
                                fontWeight: "bold",
                                marginRight: "1.5rem",
                              }}
                            >
                              {item.feature}
                            </p>
                            {item.info && (
                              <IconContainer>
                                <HoverInfo
                                  style={{
                                    width: "2rem",
                                    minWidth: "2rem",
                                    height: "2rem",
                                    minHeight: "2rem",
                                  }}
                                />
                                <ToolTip id="ToolTip">{item.info}</ToolTip>
                              </IconContainer>
                            )}
                          </Tables.TableRowColumnName>
                          <Tables.TableRowColumnChecked>
                            {item.individual && (
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 24 24"
                                aria-label="Included"
                                class="css-fpz9h8"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                              </svg>
                            )}
                          </Tables.TableRowColumnChecked>
                          <Tables.TableRowColumnChecked>
                            {item.team && (
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 24 24"
                                aria-label="Included"
                                class="css-fpz9h8"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                              </svg>
                            )}
                          </Tables.TableRowColumnChecked>
                          <Tables.TableRowColumnChecked>
                            {item.enterprise && (
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 24 24"
                                aria-label="Included"
                                class="css-fpz9h8"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                              </svg>
                            )}
                          </Tables.TableRowColumnChecked>
                        </Tables.TableRow>
                      )
                  })}
            </tbody>
          </Tables.TableDesktop>

          <Tables.TableMobile>
            <thead>
              <tr>
                <Tables.TableHeaderColumnTitle />
                <Tables.TableHeaderColumnValue>
                  <p style={{ fontWeight: "bold" }}>Individual</p>
                </Tables.TableHeaderColumnValue>
                <Tables.TableHeaderColumnValue>
                  <p style={{ fontWeight: "bold" }}>Team</p>
                </Tables.TableHeaderColumnValue>
                <Tables.TableHeaderColumnValue>
                  <p style={{ fontWeight: "bold" }}>Enterprise</p>
                </Tables.TableHeaderColumnValue>
              </tr>
            </thead>
            <tbody>
              {isExpanded
                ? PlanComparisons.map((item, index) => {
                    return (
                      <Tables.TableRow>
                        <Tables.TableRowColumnName>
                          <p
                            style={{
                              fontWeight: "bold",
                              marginRight: "1.5rem",
                            }}
                          >
                            {item.feature}
                          </p>
                          {item.info && (
                            <IconContainer>
                              <HoverInfo
                                style={{
                                  width: "2rem",
                                  minWidth: "2rem",
                                  height: "2rem",
                                  minHeight: "2rem",
                                }}
                              />
                              <ToolTip id="ToolTip">{item.info}</ToolTip>
                            </IconContainer>
                          )}
                        </Tables.TableRowColumnName>
                        <Tables.TableRowColumnChecked>
                          {item.individual && (
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 24 24"
                              aria-label="Included"
                              class="css-fpz9h8"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                            </svg>
                          )}
                        </Tables.TableRowColumnChecked>
                        <Tables.TableRowColumnChecked>
                          {item.team && (
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 24 24"
                              aria-label="Included"
                              class="css-fpz9h8"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                            </svg>
                          )}
                        </Tables.TableRowColumnChecked>
                        <Tables.TableRowColumnChecked>
                          {item.enterprise && (
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 24 24"
                              aria-label="Included"
                              class="css-fpz9h8"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                            </svg>
                          )}
                        </Tables.TableRowColumnChecked>
                      </Tables.TableRow>
                    )
                  })
                : PlanComparisons.map((item, index) => {
                    if (index <= 10)
                      return (
                        <Tables.TableRow>
                          <Tables.TableRowColumnName>
                            <p
                              style={{
                                fontWeight: "bold",
                                marginRight: "1.5rem",
                              }}
                            >
                              {item.feature}
                            </p>
                            {item.info && (
                              <IconContainer>
                                <HoverInfo
                                  style={{
                                    width: "2rem",
                                    minWidth: "2rem",
                                    height: "2rem",
                                    minHeight: "2rem",
                                  }}
                                />
                                <ToolTip id="ToolTip">{item.info}</ToolTip>
                              </IconContainer>
                            )}
                          </Tables.TableRowColumnName>
                          <Tables.TableRowColumnChecked>
                            {item.individual && (
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 24 24"
                                aria-label="Included"
                                class="css-fpz9h8"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                              </svg>
                            )}
                          </Tables.TableRowColumnChecked>
                          <Tables.TableRowColumnChecked>
                            {item.team && (
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 24 24"
                                aria-label="Included"
                                class="css-fpz9h8"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                              </svg>
                            )}
                          </Tables.TableRowColumnChecked>
                          <Tables.TableRowColumnChecked>
                            {item.enterprise && (
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 24 24"
                                aria-label="Included"
                                class="css-fpz9h8"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                              </svg>
                            )}
                          </Tables.TableRowColumnChecked>
                        </Tables.TableRow>
                      )
                  })}
            </tbody>
          </Tables.TableMobile>

          <Buttons.ButtonLink
            style={{ alignSelf: "center", width: 250 }}
            onClick={() => toggleExpand()}
          >
            {isExpanded ? "Show Less Features" : "More Features"}
          </Buttons.ButtonLink>
        </Containers.SectionContainer>
        <Containers.PromoContainer>
          <div style={{ flexGrow: 1 }}>
            <h4>Explore Uuoni for XX days</h4>
            <p>Free trial. No credit card required.</p>
          </div>
          <Buttons.ButtonLink
            style={{ background: "#ffffff", color: "#222222", width: "15rem" }}
            href="#"
          >
            Start Free Trial
          </Buttons.ButtonLink>
        </Containers.PromoContainer>
        <Containers.SectionContainer style={{ flexDirection: "column" }}>
          <div
            style={{
              alignSelf: "center",
              marginTop: "auto",
              marginBottom: "auto",
              textAlign: "center",
              width: "80%",
            }}
          >
            <h2>Frequently Asked Questions</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
            </p>
          </div>
          <Containers.FAQContainer
            style={{ marginTop: "5rem", alignSelf: "center" }}
          >
            {Faqs.map(item => (
              <div>
                <h6 style={{ fontWeight: "bold", marginBottom: 30 }}>
                  {item.question}
                </h6>
                <p>{item.answer}</p>
              </div>
            ))}
          </Containers.FAQContainer>
          <div
            style={{
              display: "flex",
              alignSelf: "center",
              alignItems: "center",
              marginTop: "3rem",
            }}
          >
            <p>Have more questions?</p>
            <Buttons.ButtonLinkArrowed href="#">
              <p style={{ fontWeight: "bold" }}>Contact Us</p>
            </Buttons.ButtonLinkArrowed>
          </div>
        </Containers.SectionContainer> */
}
