import styled from "styled-components"

const TableDesktop = styled.table`
  display: none;
  width: 100%;
  margin-top: 2rem;
  text-align: center;
  table-layout: fixed;

  @media screen and (min-width: 1025px) {
    display: table;
  }
`

const TableMobile = styled.table`
  display: table;
  width: 100%;
  margin-top: 2rem;
  text-align: center;
  table-layout: fixed;

  @media screen and (min-width: 1025px) {
    display: none;
  }
`

const TableHeaderColumnTitle = styled.th`
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-image: initial;
  padding: 1.25rem;
  text-align: center;
  width: 55%;
`

const TableHeaderColumnValue = styled.th`
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-image: initial;
  padding: 1.25rem;
  text-align: center;
  width: 15%;

  @media screen and (max-width: 1024px) {
    padding: 0;
    width: 40%;
  }
`

const TableRow = styled.tr`
  border: none;

  :nth-of-type(even) {
    background-color: #d7e0eb;
  }
`

const TableRowColumnName = styled.th`
  border: none;
  font-weight: 600;
  text-align: left;
  padding: 1.5rem;
  display: flex;

  :first-child {
    padding: 1.5rem;
  }
`

const TableRowColumnChecked = styled.td`
  text-align: center;
  border-top: none;
  border-right: none;
  border-left: none;
  border-image: initial;
  border-bottom: none;
  padding: 1.5rem;
`

// @media screen and (min-width: 1025px) {
//   padding-top: 0.75rem;
// }

export {
  TableDesktop,
  TableMobile,
  TableHeaderColumnTitle,
  TableHeaderColumnValue,
  TableRow,
  TableRowColumnName,
  TableRowColumnChecked,
}
