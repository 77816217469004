import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import * as Buttons from "../Buttons"
import * as Containers from "../Containers"
import PlansData from "../Data/plansData"
import * as Icons from "../Icons"
import * as Lists from "../Lists"

const PricingTable = props => {
  return (
    <Containers.PriceGridContainer>
      {PlansData.map((item, index) => {
        return (
          <Containers.PriceGridContainerItem
            // style={{ background: item.name === "Individual" && "#F7FAFC" }}
            style={{
              border: item.recommended && "2px solid #5E80E6",
            }}
          >
            <div style={{ height: 42 }}>
              {item.recommended && (
                <Containers.PriceGridContainerItemPromoBanner>
                  <h4
                    style={{
                      color: "#5E80E6",
                      margin: "auto",
                      fontWeight: 600,
                      fontSize: "1.125rem",
                      lineHeight: 1.235,
                    }}
                  >
                    Most Popular Plan
                  </h4>
                </Containers.PriceGridContainerItemPromoBanner>
              )}
            </div>
            <Containers.PriceGridContainerItemContent
              style={{
                borderRadius: item.recommended && "0px 0px 8px 8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: 340,
                }}
              >
                <h3
                  style={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                    fontSize: "1.75rem",
                    lineHeight: 1.2,
                  }}
                >
                  {item.name}
                </h3>
                <Containers.PriceGridContainerItemHeaderContainer>
                  {item.priceMth >= 0 && item.priceYr >= 0 ? (
                    <>
                      <h3
                        style={{
                          fontWeight: 400,
                          fontSize: "1.25rem",
                          lineHeight: 1.167,
                          marginBottom: 0,
                        }}
                      >
                        Starts at <b>30 Days Free*</b>, then at
                      </h3>
                      {props.yearly ? (
                        <>
                          <h1
                            style={{
                              color: "#5E80E6",
                              lineHeight: 1.5,
                              fontWeight: 600,
                              fontSize: "2.25rem",
                              marginBottom: 0,
                            }}
                          >
                            {item.priceYr === 0 ? `S$XX` : `S$${item.priceYr}`}
                          </h1>
                          <h3
                            style={{
                              fontWeight: 400,
                              fontSize: "1.25rem",
                              lineHeight: 1.167,
                              marginBottom: 0,
                            }}
                          >
                            per year
                          </h3>
                        </>
                      ) : (
                        <>
                          <h1
                            style={{
                              color: "#5E80E6",
                              lineHeight: 1.5,
                              fontWeight: 600,
                              fontSize: "2.25rem",
                              marginBottom: 0,
                            }}
                          >
                            {item.priceMth === 0
                              ? `S$XX`
                              : `S$${item.priceMth}`}
                          </h1>
                          <h3
                            style={{
                              fontWeight: 400,
                              fontSize: "1.25rem",
                              lineHeight: 1.167,
                              marginBottom: 0,
                            }}
                          >
                            per month
                          </h3>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <h3
                        style={{
                          fontWeight: 400,
                          fontSize: "1.25rem",
                          lineHeight: 1.167,
                          marginBottom: 0,
                        }}
                      >
                        Reach out to us to get a Pricing Quotation for your
                        needs.
                      </h3>
                    </>
                  )}
                </Containers.PriceGridContainerItemHeaderContainer>

                <Containers.PriceGridContainerItemContentContainer>
                  <h3
                    style={{
                      color: "#1B5998",
                      textTransform: "uppercase",
                      fontWeight: 600,
                      fontSize: "1.25rem",
                      lineHeight: 1.167,
                      marginBottom: 0,
                    }}
                  >
                    {item.heading}
                  </h3>
                  <p
                    style={{
                      marginTop: 8,
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: 1.5,
                      marginBottom: 0,
                    }}
                  >
                    {item.subHeading}
                  </p>
                </Containers.PriceGridContainerItemContentContainer>
              </div>
              <div className="divider" />
              <Containers.PriceGridContainerItemFeatureListContainer>
                {item.featureList.map((feature, index) => (
                  <Containers.PriceGridContainerItemFeatureListItem key={index}>
                    <Icons.Check />
                    <p
                      style={{
                        marginLeft: 8,
                        fontWeight: 400,
                        fontSize: "1rem",
                        lineHeight: 1.5,
                      }}
                    >
                      {feature.label}
                    </p>
                  </Containers.PriceGridContainerItemFeatureListItem>
                ))}
              </Containers.PriceGridContainerItemFeatureListContainer>
              <>
                {item.name === "Business" ? (
                  <PricingButton
                    style={{
                      marginTop: 16,
                      width: "100%",
                      alignSelf: "center",
                      color: "#F1F3FA",
                      textDecoration: "none",
                    }}
                    href="https://www.uuoni.com/contact"
                    enabled={true}
                  >
                    Talk to Us
                  </PricingButton>
                ) : (
                  <PricingButton
                    style={{
                      marginTop: 16,
                      width: "100%",
                      alignSelf: "center",
                    }}
                    href="https://app.uuoni.com/register"
                    enabled={item.enabled}
                  >
                    {item.enabled
                      ? `Start Free with ${item.name}`
                      : `Coming Soon`}
                  </PricingButton>
                )}
              </>
            </Containers.PriceGridContainerItemContent>
          </Containers.PriceGridContainerItem>
        )
      })}
    </Containers.PriceGridContainer>
  )
}

export default PricingTable

const PricingButton = styled(Link)`
  color: ${props => (props.enabled ? "#f1f3fa" : "rgba(0, 0, 0, 0.26)")};
  background: ${props => (props.enabled ? "#1b5998" : "rgba(0, 0, 0, 0.12)")};
  box-shadow: ${props =>
    props.enabled
      ? "0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
      : "none"};
  cursor: ${props => (props.enabled ? "pointer" : "default")};
  padding: 8px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 50px;
  text-transform: unset;
  border: 0;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;

  :hover {
    background: ${props => props.enabled && "#13406d"};
    text-decoration: none;
    transition: all 0.25s;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    flex-direction: ${props => (props.column ? "column" : "row")};
  }
`
